<template>
    <div class="getLockPassword">
        <van-button type="primary" text="显示遮罩层" @click="show = true" />
        <van-overlay :show="show" @click="show = false">
            <div class="wrapper" @click.stop>
                <div class="block" >
                    <!--  获取密码的div-->
                    <div class="getPassword">
                        获取密码
                    </div>
                    <!--  门锁密码展示的DIV-->
                    <div class="LockPassWord">
                        <p>{{userName}}</p>
                        <p class="password">{{ownerMobile}} </p>
                    </div>
                </div>
                <div class="closeImg" @click="closeMobileModel">
                </div>
            </div>
        </van-overlay>

    </div>
</template>

<script>
    import { Overlay,Button} from 'vant';
    export default {
        components: {
            [Overlay .name]:Overlay,
            [Button .name]:Button
        },
        props: {
            ownerMobile: {
                type: String,
                default:''
            },
            userName: {
                type: String,
                default:''
            },
            mobileShow: {
                type: Boolean,
                default:false
            },
        },
        data() {
            return {
                show: false,
                privateDoorPassword:'555666',
            }
        },
        methods:{
            closeMobileModel(){
                // alert("aa")
                // this.mobileShow = !this.mobileShow

                this.$emit('hideDialog');
            }
        }
    }
</script>

<style lang="less" scoped >
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .block {
        width: 76%;
        height: 21.5%;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
    }
    /*    获取密码DIV*/
    .getPassword{
        background: url("../../../assets/images/ContactPerson-img.png") no-repeat center center,
        linear-gradient(to right,#FFC274 ,#FF5D3B);
        text-align: center;
        height: 50px;
        line-height: 53px;
        color: white;
        background-size: 100%;
        font-size: 16px;
    }
    /*    门锁密码*/
    .LockPassWord{
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 14px;
    }

    /*传入的密码值*/
    .password{
        color: #ff8247;
        font-size: 20px;
        font-family: SimSun;
    }
    /*关闭按钮*/
    .closeImg{
        margin-top: 100px;
        height: 30px;
        width: 30px;
        border: 1px solid white;
        border-radius: 50%;
        background: url("../../../assets/images/GetDoorPassword-closeImg.png") no-repeat center center;
        background-size:18px 18px;
    }
</style>